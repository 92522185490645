$prod-slider-slide-margin-left: 20px !default;

$prod-slider-record-height--mobile: 250px !default;
$prod-slider-record-height--desktop: 300px !default;
$prod-slider-record-border-radius: border-radius(l) !default;
$prod-slider-record-bg-color: lighten(color(beige-1), 5%) !default;

$prod-slider-caption-position: 12px !default;
$prod-slider-caption-size: size(xl) !default;
$prod-slider-caption-border-radius: border-radius(s) !default;

$prod-slider-content-margin: spacing(s) !default;
$prod-slider-header-margin-bottom: spacing(xs) !default;
$prod-slider-headline-font-size: font-size(s) !default;
$prod-slider-headline-line-height: line-height(s) !default;
$prod-slider-headline-font-family: $font-base !default;
$prod-slider-headline-color: color(base) !default;
$prod-slider-headline-margin-bottom: spacing(xxs) !default;
$prod-slider-price-font-family: $font-base !default;
$prod-slider-price-color: color(base) !default;
$prod-slider-price-font-size: font-size(s) !default;
$prod-slider-price-margin-bottom: spacing(xs) !default;
$prod-slider-price-margin-right: spacing(xxs) !default;
$prod-slider-color-size: size(s) !default;

$prod-slider-list-font-family: $font-base !default;
$prod-slider-list-item-margin-bottom: spacing(s) !default;
$prod-slider-list-error-font-size: font-size(xxs) !default;
$prod-slider-list-error-color: color(red) !default;
$prod-slider-list-error-margin-top: spacing(xxs) !default;
$prod-slider-list-label-font-size: 14px !default;
$prod-slider-list-label-color: color(base) !default;
$prod-slider-list-label-height: size(l) !default;

$prod-slider-slide-footer-btn-height: size(xl) !default;
$prod-slider-slide-footer-btn-font-size: font-size(xs) !default;
$prod-slider-slide-footer-btn-color: color(base) !default;
$prod-slider-slide-footer-btn-hover-color: lighten(color(base), 10%) !default;
$prod-slider-slide-footer-btn-primary-padding: spacing(xs) !default;
$prod-slider-slide-footer-btn-primary-bg-color: color(red) !default;
$prod-slider-slide-footer-btn-primary-color: color(white) !default;
$prod-slider-slide-footer-btn-primary-hover-bg-color: darken(color(red), 5%) !default;

$prod-slider-arrows-bg-color: color(white) !default;
$prod-slider-arrows-size: 40px !default;
$prod-slider-arrows-prev-border-radius: 0 border-radius(s) border-radius(s) 0 !default;
$prod-slider-arrows-next-border-radius: border-radius(s) 0 0 border-radius(s) !default;

$prod-slider-bullets-width--mobile: 15px !default;
$prod-slider-bullets-width--desktop: 100px !default;
$prod-slider-bullets-height: 4px !default;
$prod-slider-bullets-margin: spacing(xxxs) !default;
$prod-slider-bullets-active-bg-color: color(red) !default;

.c-product-slider {
    position: relative;
    padding-left: 0 !important;
    width: calc(100% + 40px) !important;
    margin-left: -20px;

    @media only screen and (min-width: 768px) {
        width: 100% !important;
        margin-left: 0;
    }

    .glider {
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    li {
        list-style-type: none;
    }

    .c-product-slider__slide {
        margin-left: $prod-slider-slide-margin-left;
    }

    .c-product-slider__record {
        height: $prod-slider-record-height--mobile;
        width: 100%;
        position: relative;
        background-color: $prod-slider-record-bg-color;
        border-radius: $prod-slider-record-border-radius;

        a {
            display: block;
            height: 100%;
        }

        @media only screen and (min-width: 1024px) {
            height: $prod-slider-record-height--desktop;
        }

        .product-image-container {
            width: 100% !important;
            height: 100%;

            .product-image-wrapper {
                width: 100%;
                height: 100%;
                z-index: 0;

                img {
                    width: calc(100% - 60px);
                    height: calc(100% - 60px);
                    position: absolute;
                    object-fit: contain;
                    margin: 30px;
                }
            }
        }

        .c-product-slider__caption {
            position: absolute;
            bottom: $prod-slider-caption-position;
            right: $prod-slider-caption-position;
            width: $prod-slider-caption-size;
            height: $prod-slider-caption-size;
            border-radius: $prod-slider-caption-border-radius;
            font-weight: normal;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;

            .c-icon {
                &:before {
                    margin: 0;
                }
            }
        }
    }

    .c-product-slider__content {
        margin: $prod-slider-content-margin 0;
    }

    .c-product-slider__header {
        margin-bottom: $prod-slider-header-margin-bottom;
    }

    .c-product-slider__headline {
        font-size: $prod-slider-headline-font-size;
        line-height: $prod-slider-headline-line-height;
        font-family: $prod-slider-headline-font-family;
        font-weight: bold;
        color: $prod-slider-headline-color;
        margin-bottom: $prod-slider-headline-margin-bottom;
        min-height: 65px;
    }

    .price-box {
        font-family: $font-base;
        font-weight: bold;
        margin-top: 0;
        margin-bottom: $prod-slider-price-margin-bottom;
        display: flex;
        visibility: visible;

        .price-container {
            align-items: center;
        }

        .price-label {
            font-size: $prod-slider-price-font-size;
            color: $prod-slider-price-color;
            margin-right: $prod-slider-price-margin-right;
            margin-left: 0;
        }

        .price {
            font-size: 22px !important;
            color: $prod-slider-price-color;
        }

        .old-price {
            display: none;
        }

        .special-price {
            position: static;
        }
    }

    .tax-details {
        display: none;
    }

    .product-item-info {
        .color {
            .swatch-attribute-options {
                display: flex;
                flex-wrap: wrap;
                margin-top: 0;
                margin-bottom: 8px;

                .swatch-option {
                    border-radius: 4px;
                    height: 30px;
                    width: 32px;
                    max-width: 32px;
                    min-width: 32px;
                    margin: 0 8px 8px 0;

                    &:not(.disabled):hover {
                        outline: 2px solid color(red);
                    }

                    &.selected {
                        outline: 2px solid color(red);
                        border: none;
                    }

                    &.image:not(.disabled):hover {
                        border: none;
                    }
                }
            }
        }

        .groesse {
            .swatch-attribute-options {
                display: flex;
                flex-wrap: wrap;
                margin-top: 0;
                margin-bottom: 8px;

                .swatch-option {
                    border-radius: 4px;
                    height: auto;
                    min-height: 32px;
                    width: fit-content;
                    background-color: #AA967835;
                    display: flex;
                    align-items: center;
                    border: none;
                    margin: 0 8px 8px 0;

                    &.text {
                        font-size: 14px;
                        color: #646363;
                        padding: 0 12px;
                    }

                    &.text.selected {
                        background-color: #AA967835 !important;
                    }

                    &:not(.disabled):hover {
                        outline: 2px solid color(red);
                    }

                    &.selected {
                        outline: 2px solid color(red);
                    }

                    &:after {
                        background: linear-gradient(to left top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 48%, rgba(100, 99, 99, 1) 49%, rgba(100, 99, 99, 1) 51%, rgba(255, 255, 255, 0) 52%, rgba(255, 255, 255, 0) 100%);
                        opacity: 0.5;
                    }

                    &.disabled {
                        opacity: 0.5;
                    }
                }
            }
        }
    }

    .c-product-slider__features {
        visibility: hidden;
        opacity: 0;
        top: -20px;
        position: absolute;
    }

    .c-product-slider__slide__footer {
        display: flex;
        justify-content: space-between;
        margin-top: 11px;

        .c-product-slider__slide__footer__btn {
            height: $prod-slider-slide-footer-btn-height;
            border: none;
            background-color: transparent;
            font-size: $prod-slider-slide-footer-btn-font-size;
            font-weight: bold;
            color: $prod-slider-slide-footer-btn-color;
            text-decoration: underline;
            padding: 0;
            padding-right: 12px;

            &:hover {
                color: $prod-slider-slide-footer-btn-hover-color;
            }

            &:active {
                box-shadow: none;
            }

            &.c-product-slider__slide__footer__btn--primary {
                padding: 0 $prod-slider-slide-footer-btn-primary-padding;
                background-color: $prod-slider-slide-footer-btn-primary-bg-color;
                color: $prod-slider-slide-footer-btn-primary-color;
                text-decoration: none;
                border-radius: 4px;

                &:hover {
                    background-color: $prod-slider-slide-footer-btn-primary-hover-bg-color;
                }
            }
        }
    }

    // Product Slider Custom Styles
    .c-product-slider__arrows {
        background-color: $prod-slider-arrows-bg-color;
        color: color(base);
        border: none;
        position: absolute;
        top: calc(250px / 2);
        transform: translateY(-50%);
        cursor: pointer;
        width: $prod-slider-arrows-size;
        height: $prod-slider-arrows-size;
        display: none;
        justify-content: center;
        align-items: center;

        @media only screen and (min-width: 1024px) {
            top: calc(300px / 2);
            display: flex;
        }

        &.c-product-slider__arrows--prev {
            border-radius: $prod-slider-arrows-prev-border-radius;
        }

        &.c-product-slider__arrows--next {
            right: 0;
            border-radius: $prod-slider-arrows-next-border-radius;
        }

        &.disabled {
            display: none;
        }
    }

    .c-product-slider__bullets {
        margin-top: spacing(xs);

        @media only screen and (min-width: 1024px) {
            margin-top: spacing(s);
        }

        button {
            width: $prod-slider-bullets-width--mobile;
            height: $prod-slider-bullets-height;
            border-radius: 0;
            margin: $prod-slider-bullets-margin;
            background-color: #AA967825;

            @include mq($from: desktop) {
                width: $prod-slider-bullets-width--desktop;
            }

            &.active {
                background-color: $prod-slider-bullets-active-bg-color;
                box-shadow: none;
                border: none;
            }

            &:hover {
                border: none;
            }
        }
    }

    .c-icon {
        font-size: font-size(l);
    }
}

.c-product-slider__headline-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: spacing(m);
    align-items: flex-end;
    margin-top: 32px;

    @media only screen and (min-width: 1024px) {
        margin-bottom: spacing(l);
        margin-top: 0;
    }

    .c-product-slider__headline__arrow {
        width: 45px;
        margin-right: 10px;
        margin-top: 0;

        @media only screen and (min-width: 650px) {
            width: 80px;
            margin-right: 30px;
            margin-top: -8px;
        }
    }

    .c-product-slider__headline__title {
        font-family: $font-headline;
        color: color(base);
        font-size: font-size(xxl-m);
        text-align: center;
        min-height: 0;
        margin-bottom: 0;

        @media only screen and (min-width: 1024px) {
            font-size: font-size(xxl);
        }
    }
}

.u-1\/2\-m {
    .c-product-slider__headline-wrapper {
        @media only screen and (min-width: 1024px) {
            margin-top: 90px;
        }
    }
}

.is-hidden {
    .c-product-slider__headline {
        display: none;
    }
}

.c-product-slider__content {
    display: flex;
    flex-direction: column;

    .price-box {
        justify-content: space-between;
    }

    .swatch-attribute {
        margin-bottom: 12px;
    }

    .is-visible {
        order: 3;
    }

    .is-hidden {
        order: 2;
    }

    .swatch-opt-reversed {
        order:1;
        display: flex;
        flex-direction: column-reverse;
    }
}

.catalog-category-view {
    .c-product-slider__headline-wrapper {
        margin: 0;
    }
    .c-product-slider__headline__arrow,
    .c-product-slider__headline__title {
        padding-bottom: 24px;
    }
    @media only screen and (min-width: 1024px) {
        .c-product-slider__bullets {
            display: none;
        }
    }
}
